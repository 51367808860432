<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12.348V11.396H14.98V12.348H0ZM0 8.484V7.532H14.98V8.484H0ZM13.16 19.88C11.704 19.88 10.36 19.6373 9.128 19.152C7.896 18.648 6.82267 17.948 5.908 17.052C4.99333 16.156 4.27467 15.1107 3.752 13.916C3.248 12.7027 2.996 11.3773 2.996 9.94C2.996 8.50267 3.248 7.18667 3.752 5.992C4.27467 4.77867 4.99333 3.724 5.908 2.828C6.82267 1.932 7.896 1.24133 9.128 0.756C10.36 0.252 11.704 0 13.16 0C14.5413 0 15.8387 0.224 17.052 0.671999C18.2653 1.12 19.3013 1.80133 20.16 2.716L19.236 3.64C18.396 2.81867 17.472 2.23067 16.464 1.876C15.456 1.50267 14.3733 1.316 13.216 1.316C11.9653 1.316 10.808 1.53067 9.744 1.96C8.68 2.38933 7.74667 2.996 6.944 3.78C6.16 4.564 5.544 5.47867 5.096 6.524C4.66667 7.56933 4.452 8.708 4.452 9.94C4.452 11.172 4.66667 12.3107 5.096 13.356C5.544 14.4013 6.16 15.316 6.944 16.1C7.74667 16.884 8.68 17.4907 9.744 17.92C10.808 18.3493 11.9653 18.564 13.216 18.564C14.3733 18.564 15.456 18.3867 16.464 18.032C17.472 17.6587 18.396 17.052 19.236 16.212L20.16 17.136C19.3013 18.0507 18.2653 18.7413 17.052 19.208C15.8387 19.656 14.5413 19.88 13.16 19.88Z"
      fill="black"
    />
  </svg>
</template>
