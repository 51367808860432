<template>
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.4 21.56V20.356L4.788 20.16V20.076C3.74267 19.572 2.856 18.8907 2.128 18.032C1.41867 17.1733 0.886667 16.1747 0.532 15.036C0.177333 13.8787 0 12.6373 0 11.312C0 9.44533 0.345334 7.80267 1.036 6.384C1.72667 4.96533 2.72533 3.85467 4.032 3.052C5.35733 2.24933 6.944 1.848 8.792 1.848C10.6213 1.848 12.18 2.24933 13.468 3.052C14.7747 3.85467 15.7827 4.99333 16.492 6.468C17.2013 7.94267 17.556 9.69733 17.556 11.732H15.876C15.876 9.97733 15.6147 8.46533 15.092 7.196C14.5693 5.908 13.7853 4.91867 12.74 4.228C11.6947 3.51867 10.3787 3.164 8.792 3.164C7.224 3.164 5.908 3.51867 4.844 4.228C3.79867 4.91867 3.00533 5.88 2.464 7.112C1.94133 8.344 1.68 9.78133 1.68 11.424C1.68 13.1413 1.988 14.6627 2.604 15.988C3.22 17.2947 4.09733 18.312 5.236 19.04C6.39333 19.768 7.76533 20.132 9.352 20.132H16.324V21.56H1.4ZM5.992 11.732V0H7.252V11.732H5.992ZM10.36 11.732V0H11.62V11.732H10.36Z"
      fill="black"
    />
  </svg>
</template>
