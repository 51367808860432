<template>
  <svg
    width="15"
    height="27"
    viewBox="0 0 15 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.664 26.32V0H7.7V26.32H6.664ZM7.14 23.1C5.684 23.1 4.30267 22.8573 2.996 22.372C1.708 21.868 0.709333 21.224 0 20.44L0.644 19.376C1.316 20.0667 2.23067 20.6547 3.388 21.14C4.564 21.6067 5.80533 21.84 7.112 21.84C8.41867 21.84 9.48267 21.672 10.304 21.336C11.144 20.9813 11.76 20.5147 12.152 19.936C12.5627 19.3573 12.768 18.7133 12.768 18.004C12.768 17.164 12.544 16.492 12.096 15.988C11.648 15.484 11.06 15.0827 10.332 14.784C9.604 14.4853 8.81067 14.2333 7.952 14.028C7.09333 13.8227 6.22533 13.608 5.348 13.384C4.47067 13.1413 3.668 12.8333 2.94 12.46C2.212 12.0867 1.624 11.5827 1.176 10.948C0.746667 10.2947 0.532 9.45467 0.532 8.428C0.532 7.49467 0.774667 6.636 1.26 5.852C1.74533 5.068 2.50133 4.43333 3.528 3.948C4.55467 3.46267 5.87067 3.22 7.476 3.22C8.55867 3.22 9.62267 3.37867 10.668 3.696C11.732 4.01333 12.6373 4.44267 13.384 4.984L12.852 6.132C12.0307 5.572 11.144 5.16133 10.192 4.9C9.25867 4.62 8.35333 4.48 7.476 4.48C6.22533 4.48 5.18933 4.65733 4.368 5.012C3.54667 5.36667 2.94 5.84267 2.548 6.44C2.156 7.01867 1.96 7.672 1.96 8.4C1.96 9.24 2.17467 9.912 2.604 10.416C3.052 10.92 3.64 11.3213 4.368 11.62C5.096 11.9187 5.89867 12.1707 6.776 12.376C7.65333 12.5813 8.52133 12.8053 9.38 13.048C10.2387 13.272 11.032 13.5707 11.76 13.944C12.488 14.3173 13.076 14.8213 13.524 15.456C13.972 16.0907 14.196 16.912 14.196 17.92C14.196 18.8347 13.944 19.6933 13.44 20.496C12.936 21.28 12.1613 21.9147 11.116 22.4C10.0893 22.8667 8.764 23.1 7.14 23.1Z"
      fill="black"
    />
  </svg>
</template>
